.square {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 3vh;
    color: #000;
    transition: 0.1s;
    width: 100%;
    aspect-ratio: 1;
    padding: 0;
    margin: 0;
}

.redSquare {
    background-color: var(--red);
}

.redSquare:not(.winningSquare) {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
}

.blueSquare {
    background-color: var(--blue);
}

.blueSquare:not(.winningSquare) {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
}

.selectedSquare {
    background-color: var(--green);
}

.optionSquare {
    background-color: var(--yellow);
}

.compOptionSquare {
    background-color: #ccc;
}

.notOptionSquare {
    background-color: #eee;
}

.winningSquare {
    animation-name: pulse-together;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    box-shadow: 0 1px 3px 1px #333;
    z-index: 10;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        z-index: 10;
        box-shadow: 0 1px 0px 0px #333;
    }
    40% {
        transform: scale(1.2);
        z-index: 10;
        box-shadow: 0 1px 3px 1px #333;
    }
    100% {
        transform: scale(1);
        z-index: 10;
        box-shadow: 0 1px 0px 0px #333;
    }
}

@keyframes pulse-together {
    0% {
        transform: scale(1);
        z-index: 10;
    }
    40% {
        transform: scale(1.2);
        z-index: 10;
    }
    100% {
        transform: scale(1);
        z-index: 10;
    }
}
