.grid-border {
    position: relative;
    border: 0.5rem solid var(--red);
    border-radius: 0.5rem;
    margin: 0 0 0.5rem 0;
    transition: 0.3s;
    height: var(--mobile-grid-size);
    width: var(--mobile-grid-size);
    aspect-ratio: 1;
}

.grid-border.red {
    border-color: var(--red);
}

.grid-border.blue {
    border-color: var(--blue);
}

.grid {
    position: relative;
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(6, 1fr);
    row-gap: 1px;
    column-gap: 1px;
    background-color: #ccc;
    height: 100%;
    width: 100%;
    aspect-ratio: 1;
    border: 1px solid #000;
}

@media (min-width: 600px) {
    .grid-border {
        height: var(--desktop-grid-size);
        width: var(--desktop-grid-size);
    }
}