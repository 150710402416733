.settings-footer button {
    font-weight: 500;
}

.setting-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.setting-row:not(:first-child) {
    padding-top: 1rem;
    border-top: 1px solid #eee;
}

.setting-row:not(:last-child) {
    padding-bottom: 1rem;
}

.setting-row p {
    font-size: 1.2rem;
}