.first-number-selection {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}

.digit-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.1rem;
    aspect-ratio: 1;
}

.first-x {
    margin: auto;
}