.header {
    position: relative;
    margin: 0 0 0.4rem 0;
    display: flex;
    align-items: center;
}

.header h1 {
    margin-left: 0.5rem;
    font-size: 2rem;
}

.vs-icon.red {
    background-color: var(--red);
}

.vs-icon.blue {
    background-color: var(--blue);
}

.vs-icon {
    margin-left: auto;
    padding: 0.1rem 0.3rem;
    border-radius: 0.5rem;
    transition: 0.1s;
}

.vs-icon.computer-turn {
    animation: think 0.6666s ease-in-out infinite;
}

.gear-icon {
    position: relative;
    margin-left: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    filter: invert(61%) sepia(10%) saturate(8%) hue-rotate(319deg) brightness(100%) contrast(86%);
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 10;
}

.gear-icon img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@keyframes think {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}