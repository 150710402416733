.app-inner {
  	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	width: var(--mobile-grid-size);
	margin: 0.5rem auto;
}

.bottom,
.game-over {
	border: 1px solid #000;
	border-radius: 0.5rem;
	background-color: #f9f9f9;
	padding: 0.3rem;
}

.bottom button:not(.number-circle),
.game-over button {
    display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 0 auto;
	padding: 0rem;
	border: none;
	border-radius: 0.3rem;
	height: 8vh;
	font-size: 4vh;
}

.confirm {
	background-color: var(--green);
	color: #000;
}

.confirm:disabled {
	background-color: #ccc;
	color: #666;
}

.confirm.disabled > *:not(:last-child) {
	margin: 0 0.7rem 0 0;
}

.confirm:active {
	filter: brightness(90%);
}

.confirm-select-circle {
	border-radius: 50%;
	background-color: var(--light-green);
	color: #000;
	height: 2.5rem;
    min-width: 2.5rem;
    border: none;
	display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.2rem;
    font-size: 1.5rem;
}

@media (min-width: 600px) {
	.app-inner {
		width: var(--desktop-grid-size);
	}
}