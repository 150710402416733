.number-circle {
    position: relative;
    height: 5vh;
    width: 5vh;
    border: 1px solid #ddd;
    border-radius: 50%;
    background-color: var(--yellow);
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 3vh;
    transition: 0.1s;
    aspect-ratio: 1;
}

.number-circle:disabled {
    background-color: #ccc;
    border: none;
}

.number-circle.selected {
    background-color: var(--green);
    border: none;
}

.number-circle.half-selected {
    background-color: var(--light-green);
    border: none;
}