:root {
	--green: #00B865;
	--light-green: rgb(148, 220, 148);
	--yellow: rgb(255, 255, 161);
	--red: rgb(255, 116, 116);
	--blue: rgb(145, 181, 255);
	--purple: #B598C3;
	--mobile-grid-size: calc(100vw - 6rem);
	--desktop-grid-size: 40vh;
	--pulse: {
		0% {
			transform: scale(1);
		}
		40% {
			transform: scale(1.2);
			z-index: 10;
		}
		100% {
			transform: scale(1);
		}
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

* {
	box-sizing: border-box;
}

h1, p {
	margin: 0;
}

button:not(:disabled) {
	cursor: pointer;
}