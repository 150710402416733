.number-row {
    width: 100%;
    height: fit-content;
    margin: 0.25rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.number-row:nth-child(2) {
    border-top: 1px solid #ccc;
    padding-top: 0.3rem;
}

.multiplier-x {
    margin: 0 0.5rem;
    font-size: 3vh;
}

.multiplier-box {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    gap: 0.1rem;
}